<template>
  <div class="content">
    <!-- 提现 -->
    <div class="box">
      <div class="balance-box">
        <!-- <span>当前账户余额：￥{{ Balance ? Balance : "0.00" }}</span> -->
        <div>
          可提现余额：
          <span class="canOut">￥{{ Balance ? Balance : '0.00' }}</span>
        </div>
        <div>
          暂不可提现余额：
          <span class="noOut">￥{{ gwjBalance ? gwjBalance : '0.00' }}</span>
        </div>
      </div>
      <div class="input-box">
        <span
          >输入提现金额：
          <el-input
            oninput="value=value.indexOf('.') > -1?value.slice(0, value.indexOf('.') + 3):value.slice(0,7)"
            @change="change"
            :disabled="Balance === 0 ? true : false"
            v-model="money"
            placeholder="请输入金额:"
            style="width: 240px"
            size="small"
            clearable
          ></el-input>
        </span>
      </div>
      <div>
        <el-button type="primary" @click="cashOut">确认提现</el-button>
      </div>
      <div>
        <h2>1.提现开放时间(每日): 05:00:00~23:59:59</h2>
        <h2>2.转出说明：货款到账时间为T+1，非工作日顺延至工作日</h2>
        <p style="color: #999999">
          提现咨询专线：028-8768-3701 客服服务时间：8:30-17:30
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Balance: 0,
      money: null,
      gwjBalance: null,
    };
  },
  created() {
    this.getMoneyInfo();
  },
  methods: {
    // 商家查询余额
    getMoneyInfo() {
      this.$api.order.queryShopWallet().then((res) => {
        this.Balance = res.data.availableBalance;
        // this.availableBalance = res.data.availableBalance;
        this.gwjBalance = res.data.gwjBalance;
      });
    },
    inputhandle({ target }) {
      // /^[0-9]+(.?[0-9]{1,2})?$/
      /**
       * <input type="text" onkeyup="var p2 = parseFloat(value).toFixed(2);value = p2>=0?(/\.0?$/.test(value)?value:p2.replace(/0$/,'').replace(/\.0$/,'')):''" onblur="value = value.replace(/\.0*$/,'')"/>
       * */
      this.money = target.value = target.value.replace(/[^0-9]/g, '');
    },
    // 提现申请
    cashOut() {
      if (!this.money) {
        this.$message.error('提现金额不能为空!');
        return;
      }
      const that = this;
      this.$confirm('确认申请提现？', '提现', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
      })
        .then(() => {
          that.$api.order.shopWithdrawal({ money: that.money }).then((res) => {
            if (res.success) {
              that.$message({
                message: res.message,
                type: 'success',
              });
              that.getMoneyInfo();
              this.money = null;
            } else {
              that.$message({
                message: res.message,
                type: 'warning',
              });
              that.getMoneyInfo();
              this.money = null;
            }
          });
        })
        .catch(() => {});
    },
    change() {
      //  Balance

      if (this.money > Number(this.Balance)) {
        this.money = Number(this.Balance);
      }
      if (this.money <= 0) {
        this.money = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
  font-weight: 400;
  color: #606266;
  font-size: 12px;
  div {
    margin: 16px 0;
  }
  .balance-box {
    font-size: 26px;
  }
  .input-box {
    font-size: 20px;
  }
  .canOut {
    color: #67c23a;
  }
  .noOut {
    color: red;
  }
}
</style>
